import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio, Checkbox } from "antd";
import "./style.scss";
import {
  buildEffectDate,
  buildExpireDate,
  buildFormType,
  buildRecoveryType,
  buildValueGT,
  buildValueLT,
  reportProIns,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldProIns } from "@utils/form-recog";

import {
  FORM_TYPE_SPECIAL,
  FORM_TYPE_BROAD,
  FORM_TYPE_BASIC,
  FORM_LOSS_RECOVERY_TYPE_COST,
  FORM_LOSS_RECOVERY_TYPE_CASH,
} from "@assets/const/form";

import {
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";

import { capitalize } from "lodash";
import { formatToMonthDayYear } from "@utils/date-formatter";

export default function ProInsForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.pro_ins_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        pro_ins_is_bus_per_pro: finalData.pro_ins_is_bus_per_pro,
        pro_ins_bus_per_pro: finalData.pro_ins_bus_per_pro,
        pro_ins_is_ten_imp: finalData.pro_ins_is_ten_imp,
        pro_ins_ten_imp: finalData.pro_ins_ten_imp,
        pro_ins_is_bui_amo: finalData.pro_ins_is_bui_amo,
        pro_ins_bui_amo: finalData.pro_ins_bui_amo,
        pro_ins_is_bus_int: finalData.pro_ins_is_bus_int,
        pro_ins_bus_int: finalData.pro_ins_bus_int,
        pro_ins_is_act_los: finalData.pro_ins_is_act_los,
        pro_ins_act_los: finalData.pro_ins_act_los,
        pro_ins_is_mon_lim: finalData.pro_ins_is_mon_lim,
        pro_ins_mon_lim: finalData.pro_ins_mon_lim,
        pro_ins_is_co_ins: finalData.pro_ins_is_co_ins,
        pro_ins_co_ins: finalData.pro_ins_co_ins,
        pro_ins_is_agg_val: finalData.pro_ins_is_agg_val,
        pro_ins_agg_val: finalData.pro_ins_agg_val,
        pro_ins_form_type: finalData.pro_ins_form_type,
        pro_ins_los_rec_type: finalData.pro_ins_los_rec_type,
        pro_ins_policy_number: finalData.pro_ins_policy_number,
        pro_ins_effective_date: finalData.pro_ins_effective_date,
        pro_ins_expire_date: finalData.pro_ins_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex][0]?.fields;
      if (data) {
        return mapFieldProIns(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

const propInsSections = [
  {
    title: "Effective Date",
    valueFn: () =>
      buildEffectDate(
        franchiseeData.finalData.pro_ins_effective_date,
        franchisorData.pro_ins_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.pro_ins_effective_date)
  },
  {
    title: "Expiration Date",
    valueFn: () =>
      buildExpireDate(
        franchiseeData.finalData.pro_ins_expire_date,
        franchisorData.pro_ins_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.pro_ins_expire_date)
  },
  {
    title: "Business Personal Property",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_bus_per_pro,
        franchisorData.pro_ins_bus_per_pro,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_bus_per_pro,
    formValue: franchiseeData.finalData.pro_ins_bus_per_pro,
  },
  {
    title: "Tenant Improvements",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_ten_imp,
        franchisorData.pro_ins_ten_imp,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_ten_imp,
    formValue: franchiseeData.finalData.pro_ins_ten_imp,
  },
  {
    title: "Building Amount",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_bui_amo,
        franchisorData.pro_ins_bui_amo,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_bui_amo,
    formValue: franchiseeData.finalData.pro_ins_bui_amo,
  },
  {
    title: "Business Interruption",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_bus_int,
        franchisorData.pro_ins_bus_int,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_bus_int,
    formValue: franchiseeData.finalData.pro_ins_bus_int,
  },
  {
    title: "Actual Loss Sustained",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_act_los,
        franchisorData.pro_ins_act_los,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_act_los,
    formValue: franchiseeData.finalData.pro_ins_act_los,
  },
  {
    title: "Monthly Limitation",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_mon_lim,
        franchisorData.pro_ins_mon_lim,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_mon_lim,
    formValue: franchiseeData.finalData.pro_ins_mon_lim,
  },
  {
    title: "Co-Insurance",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_co_ins,
        franchisorData.pro_ins_co_ins,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_co_ins,
    formValue: franchiseeData.finalData.pro_ins_co_ins,
  },
  {
    title: "Agreed Value",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_ins_agg_val,
        franchisorData.pro_ins_agg_val,
        franchisorData.pro_ins_required
      ),
    fddRequirement: franchisorData.pro_ins_agg_val,
    formValue: franchiseeData.finalData.pro_ins_agg_val,
  },
  {
    title: "Form Type",
    valueFn: () =>
      buildFormType(
        franchiseeData.finalData.pro_ins_form_type,
        franchisorData.pro_ins_form_type,
        franchisorData.pro_ins_required
      ),
    fddRequirement: capitalize(franchisorData.pro_ins_form_type),
    formValue: capitalize(franchiseeData.finalData.pro_ins_form_type),
  },
  {
    title: "Loss Recovery Type",
    valueFn: () =>
      buildRecoveryType(
        franchiseeData.finalData.pro_ins_los_rec_type,
        franchisorData.pro_ins_los_rec_type,
        franchisorData.pro_ins_required
      ),
    fddRequirement: capitalize(franchisorData.pro_ins_los_rec_type).replace(
      /[_-]/g,
      " "
    ),
    formValue: capitalize(
      franchiseeData.finalData.pro_ins_los_rec_type
    ).replace(/[_-]/g, " "),
  },
];


  return (
    <div className="liability-table">
      <div className="table-header">
        <span className="table-header-field">Field</span>
        <span className="table-header-fdd">Requirement</span>
        <span className="table-header-coverage">Your Coverage</span>
      </div>
      {propInsSections.map(({ title, valueFn, fddRequirement, formValue }) => {
        const value = valueFn()?.[0]?.title;
        const displayValue = formValue || "No Value";
        const isNotMatched = !value && !formValue;

        return (toggleState ? value : formValue || value) ? (
          <div key={title} className="table-row">
            <div className="table-cell field-title">
              <span>{title}</span>
            </div>
            <div className="table-cell fdd-requirement">
              <span>{fddRequirement || ""}</span>
            </div>
            <div className="table-cell">
              {isNotMatched ? (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                  {displayValue}
                </span>
              ) : !toggleState ? (
                value ? (
                  <span className="value-red">
                    <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                    {value}
                  </span>
                ) : (
                  <span className="value-green">
                    <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                    {typeof displayValue === "boolean"
                      ? displayValue
                        ? "Selected"
                        : null
                      : displayValue}
                  </span>
                )
              ) : (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
                </span>
              )}
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}
