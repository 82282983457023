import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
} from "../../../../assets/const/form";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildCrimeForm,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldCrime } from "@utils/form-recog";
import {
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import { capitalize } from "lodash";
import { formatToMonthDayYear } from "@utils/date-formatter";


export default function CrimeForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState} = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.crime_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        crime_each_claim: finalData.crime_each_claim,
        crime_third_party: finalData.crime_third_party,
        crime_form_type: finalData.crime_form_type,
        crime_policy_number: finalData.crime_policy_number,
        crime_effective_date: finalData.crime_effective_date,
        crime_expire_date: finalData.crime_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldCrime(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const crimeSections = [
    {
      title: "Effective Date",
      valueFn: () =>
        buildEffectDate(
          franchiseeData.finalData.crime_effective_date,
          franchisorData.crime_required
        ),
      formValue: formatToMonthDayYear(franchiseeData.finalData.crime_effective_date)
    },
    {
      title: "Expiration Date",
      valueFn: () =>
        buildExpireDate(
          franchiseeData.finalData.crime_expire_date,
          franchisorData.crime_required
        ),
      formValue: formatToMonthDayYear(franchiseeData.finalData.crime_expire_date)
    },
    {
      title: "Each Claim",
      valueFn: () =>
        buildValueGT(
          franchiseeData.finalData.crime_each_claim,
          franchisorData.crime_each_claim,
          franchisorData.crime_required
        ),
      fddRequirement: franchisorData.crime_each_claim,
      formValue: franchiseeData.finalData.crime_each_claim,
    },
    {
      title: "3rd Party",
      valueFn: () =>
        buildValueGT(
          franchiseeData.finalData.crime_third_party,
          franchisorData.crime_third_party,
          franchisorData.crime_required
        ),
      fddRequirement: franchisorData.crime_third_party,
      formValue: franchiseeData.finalData.crime_third_party,
    },
    {
      title: "Crime Form",
      valueFn: () =>
        buildCrimeForm(
          franchiseeData.finalData.crime_form_type,
          franchisorData.crime_form_type,
          franchisorData.crime_required
        ),
      fddRequirement: capitalize(franchisorData.crime_form_type),
      formValue: capitalize(franchiseeData.finalData.crime_form_type),
    },
  ];


return (
  <div className="liability-table">
    <div className="table-header">
      <span className="table-header-field">Field</span>
      <span className="table-header-fdd">Requirement</span>
      <span className="table-header-coverage">Your Coverage</span>
    </div>
    {crimeSections.map(({ title, valueFn, fddRequirement, formValue }) => {
      const value = valueFn()?.[0]?.title;
      const displayValue = formValue || "No Value";
      const isNotMatched = !value && !formValue;

      return (toggleState ? value : formValue || value) ? (
        <div key={title} className="table-row">
          <div className="table-cell field-title">
            <span>{title}</span>
          </div>
          <div className="table-cell fdd-requirement">
            <span>{fddRequirement || ""}</span>
          </div>
          <div className="table-cell">
            {isNotMatched ? (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                {displayValue}
              </span>
            ) : !toggleState ? (
              value ? (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
                </span>
              ) : (
                <span className="value-green">
                  <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                  {typeof displayValue === "boolean"
                    ? displayValue
                      ? "Selected"
                      : null
                    : displayValue}
                </span>
              )
            ) : (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
              </span>
            )}
          </div>
        </div>
      ) : null;
    })}
  </div>
);
}
