import React, { useState, useEffect } from "react";
import "./style.scss";
import { mapFieldLiability } from "../../../../utils/form-recog";
import {
  buildGeneralPolicy,
  buildGeneralAggregateLimit,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildCertificateValid
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import {
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import { capitalize } from "lodash";
import { formatToMonthDayYear } from "@utils/date-formatter";

export default function LiabilityForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex, franchiseeData]);

  function handleGetFormVal() {
    if (
      franchiseeData?.finalData?.commercial_general_liability_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;
      return {
        commercial_general_policy: finalData.commercial_general_policy,
        commercial_general_aggregate_limit:
          finalData.commercial_general_aggregate_limit,
        commercial_general_liability_additional_insurance:
          finalData.commercial_general_liability_additional_insurance,
        commercial_general_liability_subrogration_waved:
          finalData.commercial_general_liability_subrogration_waved,
        commercial_general_liability_each_occurance:
          finalData.commercial_general_liability_each_occurance,
        commercial_general_liability_damage_rented_premises:
          finalData.commercial_general_liability_damage_rented_premises,
        commercial_general_liability_medical_expenses:
          finalData.commercial_general_liability_medical_expenses,
        commercial_general_liability_personal_adv_injury:
          finalData.commercial_general_liability_personal_adv_injury,
        commercial_general_liability_general_aggregate:
          finalData.commercial_general_liability_general_aggregate,
        commercial_general_liability_comprop_acg:
          finalData.commercial_general_liability_comprop_acg,
        commercial_general_liability_policy_number:
          finalData.commercial_general_liability_policy_number,
        commercial_general_liability_effective_date:
          finalData.commercial_general_liability_effective_date,
        commercial_general_liability_expire_date:
          finalData.commercial_general_liability_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldLiability(data);
      }
    }

    return {};
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

const liabilitySections = [
  {
    title: "Effective Date",
    valueFn: () =>
      buildEffectDate(
        franchiseeData.finalData.commercial_general_liability_effective_date,
        franchisorData.commercial_general_liability_required
      ),
    formValue:
      formatToMonthDayYear(franchiseeData.finalData.commercial_general_liability_effective_date)
  },
  {
    title: "Expiration Date",
    valueFn: () =>
      buildExpireDate(
        franchiseeData.finalData.commercial_general_liability_expire_date,
        franchisorData.commercial_general_liability_required
      ),
    formValue:
      formatToMonthDayYear(franchiseeData.finalData.commercial_general_liability_expire_date),
  },
  {
    title: "Each Occurrence",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.commercial_general_liability_each_occurance,
        franchisorData.commercial_general_liability_each_occurance,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement: franchisorData.commercial_general_liability_each_occurance,
    formValue:
      franchiseeData.finalData.commercial_general_liability_each_occurance,
  },
  {
    title: "Damage to Rented Premises",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData
          .commercial_general_liability_damage_rented_premises,
        franchisorData.commercial_general_liability_damage_rented_premises,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement:
      franchisorData.commercial_general_liability_damage_rented_premises,
    formValue:
      franchiseeData.finalData
        .commercial_general_liability_damage_rented_premises,
  },
  {
    title: "Medical Expenses",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.commercial_general_liability_medical_expenses,
        franchisorData.commercial_general_liability_medical_expenses,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement:
      franchisorData.commercial_general_liability_medical_expenses,
    formValue:
      franchiseeData.finalData.commercial_general_liability_medical_expenses,
  },
  {
    title: "Personal & Adv Injury",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData
          .commercial_general_liability_personal_adv_injury,
        franchisorData.commercial_general_liability_personal_adv_injury,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement:
      franchisorData.commercial_general_liability_personal_adv_injury,
    formValue:
      franchiseeData.finalData.commercial_general_liability_personal_adv_injury,
  },
  {
    title: "General Aggregate",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.commercial_general_liability_general_aggregate,
        franchisorData.commercial_general_liability_general_aggregate,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement:
      franchisorData.commercial_general_liability_general_aggregate,
    formValue:
      franchiseeData.finalData.commercial_general_liability_general_aggregate,
  },
  {
    title: "Product - Comp / OP AGG",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.commercial_general_liability_comprop_acg,
        franchisorData.commercial_general_liability_comprop_acg,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement: franchisorData.commercial_general_liability_comprop_acg,
    formValue:
      franchiseeData.finalData.commercial_general_liability_comprop_acg,
  },
  {
    title: "General Aggregate Limit Applies Per",
    valueFn: () =>
      buildGeneralAggregateLimit(
        franchiseeData.finalData.commercial_general_aggregate_limit,
        franchisorData.commercial_general_aggregate_limit,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement: capitalize(
      franchisorData.commercial_general_aggregate_limit
    ),
    formValue: capitalize(
      franchiseeData.finalData.commercial_general_aggregate_limit
    ),
  },
  {
    title: "Additional Insured",
    valueFn: () =>
      buildGeneralLiabilityAdditionalInsurance(
        franchiseeData.finalData
          .commercial_general_liability_additional_insurance,
        franchisorData.commercial_general_liability_additional_insurance,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement:
      franchisorData.commercial_general_liability_additional_insurance
        ? "Selected"
        : "Unselected",
    formValue:
      franchiseeData.finalData
        .commercial_general_liability_additional_insurance,
  },
  {
    title: "Waiver of Subrogation",
    valueFn: () =>
      buildGeneralLiabilitySubrogrationWaved(
        franchiseeData.finalData
          .commercial_general_liability_subrogration_waved,
        franchisorData.commercial_general_liability_subrogration_waved,
        franchisorData.commercial_general_liability_required
      ),
    fddRequirement:
      franchisorData.commercial_general_liability_subrogration_waved
        ? "Selected"
        : "Unselected",
    formValue:
      franchiseeData.finalData.commercial_general_liability_subrogration_waved,
  },
  /* Section removed for now logic still in return statement*/
  // {
  //   title: "Certificate Holder",
  //   valueFn: () => "",
  //   fddRequirement: `${franchisorData.additional_infor_business_name}, ${franchisorData.additional_infor_address}, ${franchisorData.additional_infor_city} ${franchisorData.additional_infor_state}`,
  //   formValue: franchiseeData.certificateIssue.some(
  //     (issue) => issue.title === "Certificate Holder is not matched"
  //   )
  //     ? "Certificate Holder is not matched"
  //     : franchiseeData.finalData.additional_infor_certificate_holder,
  // },
  // {
  //   title: "Description of Operations",
  //   valueFn: () => "",
  //   fddRequirement: franchisorData.additional_infor_certificate,
  //   formValue: franchiseeData.certificateIssue.some(
  //     (issue) => issue.title === "Certificate of Insurance is not matched"
  //   )
  //     ? "Certificate of Insurance is not matched"
  //     : franchiseeData.finalData.additional_infor_operation_description,
  // },
];

return (
  <div className="liability-table">
    <div className="table-header">
      <span className="table-header-field">Field</span>
      <span className="table-header-fdd">Requirement</span>
      <span className="table-header-coverage">Your Coverage</span>
    </div>
    {liabilitySections.map(({ title, valueFn, fddRequirement, formValue }) => {
      const buildValue = valueFn()?.[0]?.title;

      const displayValue =
        formValue ||
        franchiseeData.finalData.additional_infor_certificate_holder;

      if (!buildValue && !formValue) {
        return null;
      }

      const isNotMatched =
        (title === "Certificate Holder" &&
          displayValue === "Certificate Holder is not matched") ||
        (title === "Description of Operations" &&
          displayValue === "Certificate of Insurance is not matched");

      return (!toggleState || buildValue || isNotMatched) &&
        (displayValue || buildValue) ? (
        <div key={title} className="table-row">
          <div className="table-cell field-title">
            <span>{title}</span>
          </div>
          <div className="table-cell fdd-requirement">
            <span>{fddRequirement || ""}</span>
          </div>
          <div className="table-cell">
            {isNotMatched ? (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} /> {displayValue}
              </span>
            ) : !toggleState ? (
              buildValue ? (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} /> {buildValue}
                </span>
              ) : (
                <span className="value-green">
                  <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                  {typeof displayValue === "boolean"
                    ? displayValue
                      ? "Selected"
                      : null
                    : displayValue}
                </span>
              )
            ) : (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} /> {buildValue}
              </span>
            )}
          </div>
        </div>
      ) : null;
    })}
  </div>
);

}
