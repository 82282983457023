import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import { Select, Input, Loading, FormMask } from "@components";
import { Checkbox, Switch, Radio, Button, notification } from "antd";
import { useAppState } from "@context";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, get, put } from "@utils/axios";
import moment from "moment";
import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_POLICY,
  FORM_PROJECT,
  FORM_LOC,
} from "@assets/const/form";
import { mapFieldLiability } from "@utils/form-recog";
import {
  buildGeneralPolicy,
  buildGeneralAggregateLimit,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  reportLiability,
  getLiabilityScore,
} from "@utils/build-logic";
import { PERMISSION_TABLE } from "@utils/permission";
import { isEmpty } from "lodash";
import {
  DOC_MAX_LIMIT,
  INSURANCE_STATUS_NON_COMPLIANT,
  STATUS_APPROVE_LIABILITY,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
} from "@assets/const/status";
import { validateDate } from "@utils/common";
import { TYPE_INPUT_DATE } from "@assets/const/ui";
import { format } from "date-fns";

export default function LiabilityForm(props) {
  const { docIndex, franchisorData } = props;

  const { authSetting } = useAppState();
  const { getAccessTokenSilently } = useAuth0();

  const [formVal, setFormVal] = useState(handleGetFormVal());

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
      console.log("card zee data", franchiseeData);
    if (
      (docIndex >= 0 &&
        docIndex ===
          franchiseeData?.finalData
            ?.commercial_general_liability_form_doc_index) ||
      docIndex === DOC_MAX_LIMIT
    ) {
      if (
        franchiseeData?.finalData?.commercial_general_liability_form_status ===
          STATUS_SECTION_APPROVE ||
        franchiseeData?.finalData?.commercial_general_liability_form_status ===
          STATUS_SECTION_PENDING
      ) {
        const finalData = franchiseeData.finalData;
        return {
          commercial_general_policy: finalData.commercial_general_policy,
          commercial_general_aggregate_limit:
            finalData.commercial_general_aggregate_limit,
          commercial_general_liability_additional_insurance:
            finalData.commercial_general_liability_additional_insurance,
          commercial_general_liability_subrogration_waved:
            finalData.commercial_general_liability_subrogration_waved,
          commercial_general_liability_each_occurance:
            finalData.commercial_general_liability_each_occurance,
          commercial_general_liability_damage_rented_premises:
            finalData.commercial_general_liability_damage_rented_premises,
          commercial_general_liability_medical_expenses:
            finalData.commercial_general_liability_medical_expenses,
          commercial_general_liability_personal_adv_injury:
            finalData.commercial_general_liability_personal_adv_injury,
          commercial_general_liability_general_aggregate:
            finalData.commercial_general_liability_general_aggregate,
          commercial_general_liability_comprop_acg:
            finalData.commercial_general_liability_comprop_acg,
          commercial_general_liability_policy_number:
            finalData.commercial_general_liability_policy_number,
          commercial_general_liability_effective_date:
            finalData.commercial_general_liability_effective_date,
          commercial_general_liability_expire_date:
            finalData.commercial_general_liability_expire_date,
        };
      } else {
        return getEmptyForm();
      }
    }

    if (docIndex >= 0) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldLiability(data);
      }
    }

    return getEmptyForm();
  }

  function getEmptyForm() {
    return {
      commercial_general_policy: "",
      commercial_general_aggregate_limit: "",
      commercial_general_liability_additional_insurance: "",
      commercial_general_liability_subrogration_waved: "",
      commercial_general_liability_each_occurance: 0,
      commercial_general_liability_damage_rented_premises: 0,
      commercial_general_liability_medical_expenses: 0,
      commercial_general_liability_personal_adv_injury: 0,
      commercial_general_liability_general_aggregate: 0,
      commercial_general_liability_comprop_acg: 0,
      commercial_general_liability_policy_number: "",
      commercial_general_liability_effective_date: "",
      commercial_general_liability_expire_date: "",
    };
  }

  async function handleApprove() {
    let { franchiseeData, docIndex } = props;
    if (docIndex >= 0) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        let activities = franchiseeData.activities;
        if (activities) {
          activities.push({
            action: STATUS_APPROVE_LIABILITY,
            date: new Date(),
          });
        } else {
          activities = [
            {
              action: STATUS_APPROVE_LIABILITY,
              date: new Date(),
            },
          ];
        }

        let finalData = franchiseeData?.finalData
          ? franchiseeData.finalData
          : {};
        finalData = {
          ...finalData,
          ...formVal,
          commercial_general_liability_form_status:
            !isEmpty(formVal.commercial_general_liability_expire_date) &&
            new Date(formVal.commercial_general_liability_expire_date) <
              new Date()
              ? STATUS_SECTION_PENDING
              : STATUS_SECTION_APPROVE,
          commercial_general_liability_form_doc_index: docIndex,
          commercial_general_liability_expire_date: validateDate(
            formVal.commercial_general_liability_expire_date
          )
            ? format(
                new Date(formVal.commercial_general_liability_expire_date),
                "yyyy-MM-dd"
              )
            : null,
          commercial_general_liability_effective_date: validateDate(
            formVal.commercial_general_liability_effective_date
          )
            ? format(
                new Date(formVal.commercial_general_liability_effective_date),
                "yyyy-MM-dd"
              )
            : null,
        };

        const resultIssue = reportLiability(finalData, franchisorData);

        const result = await put(
          `contact/${franchiseeData._id}`,
          {
            finalData: finalData,
            activities: activities,
            liabilityIssue: resultIssue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await deleteAPI(`notification/delete/${franchiseeData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { handleUpdateForm } = props;
        handleUpdateForm(result.data.entity);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      handleNotification("Failed", "Need to select a documentation");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const coverageScore = useMemo(() => {
    let finalData = props.franchiseeData?.finalData
      ? props.franchiseeData.finalData
      : {};
    finalData = {
      ...finalData,
      ...formVal,
      commercial_general_liability_form_status:
        !isEmpty(formVal.commercial_general_liability_expire_date) &&
        new Date(formVal.commercial_general_liability_expire_date) < new Date()
          ? STATUS_SECTION_PENDING
          : STATUS_SECTION_APPROVE,
      commercial_general_liability_form_doc_index: docIndex,
      commercial_general_liability_expire_date: validateDate(
        formVal.commercial_general_liability_expire_date
      )
        ? formVal.commercial_general_liability_expire_date
        : null,
      commercial_general_liability_effective_date: validateDate(
        formVal.commercial_general_liability_effective_date
      )
        ? formVal.commercial_general_liability_effective_date
        : null,
    };

    const resultScore = getLiabilityScore(finalData, franchisorData);
    return resultScore;
  }, [formVal, franchisorData, props.franchiseeData]);

  return (
    <div className="liability-content">
      {coverageScore.visible && (
        <div className="workers-comp-content-score">
          Score: {coverageScore.score} / {coverageScore.total}
        </div>
      )}
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="radio-title">Commecial General Liability</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("commercial_general_policy", evt.target.value)
              }
              value={formVal.commercial_general_policy}
            >
              <Radio value={FORM_OCCURRENCE}>Occurrence</Radio>
              <Radio value={FORM_CLAIMS_MADE}>Claims Made</Radio>
            </Radio.Group>
            {buildGeneralPolicy(
              formVal.commercial_general_policy,
              franchisorData.commercial_general_policy,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="radio-container">
            <h5 className="radio-title">GEN”L AGGREGATE LIMIT APPLIES PER:</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange(
                  "commercial_general_aggregate_limit",
                  evt.target.value
                )
              }
              value={formVal.commercial_general_aggregate_limit}
            >
              <Radio value={FORM_POLICY}>Policy</Radio>
              <Radio value={FORM_PROJECT}>Project</Radio>
              <Radio value={FORM_LOC}>Loc</Radio>
            </Radio.Group>
            {buildGeneralAggregateLimit(
              formVal.commercial_general_aggregate_limit,
              franchisorData.commercial_general_aggregate_limit,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={
                formVal.commercial_general_liability_additional_insurance
              }
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_additional_insurance",
                  evt.target.checked
                )
              }
            >
              Additional Insured
            </Checkbox>
            {buildGeneralLiabilityAdditionalInsurance(
              formVal.commercial_general_liability_additional_insurance,
              franchisorData.commercial_general_liability_additional_insurance,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.commercial_general_liability_subrogration_waved}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_subrogration_waved",
                  evt.target.checked
                )
              }
            >
              Waiver Of Subrogation
            </Checkbox>
            {buildGeneralLiabilitySubrogrationWaved(
              formVal.commercial_general_liability_subrogration_waved,
              franchisorData.commercial_general_liability_subrogration_waved,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <Input
              title="POLICY NUMBER"
              placeholder="POLICY NUMBER"
              value={formVal.commercial_general_liability_policy_number}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_policy_number",
                  evt.target.value
                )
              }
            />
            <Input
              title="EFFECTIVE DATE"
              placeholder="EFFECTIVE DATE"
              value={formVal.commercial_general_liability_effective_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_effective_date",
                  evt.target.value
                )
              }
            />
            {buildEffectDate(
              formVal.commercial_general_liability_effective_date,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}

            <Input
              title="EXPIRATION DATE"
              placeholder="EXPIRATION DATE"
              value={formVal.commercial_general_liability_expire_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_expire_date",
                  evt.target.value
                )
              }
            />
            {buildExpireDate(
              formVal.commercial_general_liability_expire_date,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.commercial_general_liability_each_occurance}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_each_occurance",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_each_occurance,
              franchisorData.commercial_general_liability_each_occurance,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="DAMAGE TO RENTED PERMISES"
              placeholder="DAMAGE TO RENTED PERMISES"
              value={
                formVal.commercial_general_liability_damage_rented_premises
              }
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_damage_rented_premises",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_damage_rented_premises,
              franchisorData.commercial_general_liability_damage_rented_premises,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="MED EXP"
              subTitle="( Any one person )"
              placeholder="MED EXP"
              value={formVal.commercial_general_liability_medical_expenses}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_medical_expenses",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_medical_expenses,
              franchisorData.commercial_general_liability_medical_expenses,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="PERSONAL & ADV INJURY"
              placeholder="PERSONAL & ADV INJURY"
              value={formVal.commercial_general_liability_personal_adv_injury}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_personal_adv_injury",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_personal_adv_injury,
              franchisorData.commercial_general_liability_personal_adv_injury,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="GENERAL AGGREGATE"
              placeholder="GENERAL AGGREGATE"
              value={formVal.commercial_general_liability_general_aggregate}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_general_aggregate",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_general_aggregate,
              franchisorData.commercial_general_liability_general_aggregate,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="PRODUCTS - COMP / OP AGG"
              placeholder="PRODUCTS - COMP / OP AGG"
              value={formVal.commercial_general_liability_comprop_acg}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_comprop_acg",
                  evt.target.value
                )
              }
            />

            {buildValueGT(
              formVal.commercial_general_liability_comprop_acg,
              franchisorData.commercial_general_liability_comprop_acg,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-footer">
        {PERMISSION_TABLE["FRANCHISEE_DATA_APPROVE"][
          authSetting.userPermission
        ] > 0 && (
          <Button type="primary" size="large" onClick={handleApprove}>
            Approve
          </Button>
        )}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
