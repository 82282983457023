import React, { useState } from "react";
import _ from "lodash";
import { Input } from "@components";
import { Button, Switch, Radio, Select } from "antd";
import ScreenMask from "@components/screen-mask";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import "./style.scss";
import POCCreate from "@components/modals/poc-create";
import { PREFERRED_CONTACT_METHOD_LIST, ROLE_LIST } from "@assets/const/ui";

export default function BlockPOC({
  data,
  onChange,
  disabled,
  disableAdd = false,
  franchiseeData,
  setFranchiseeData,
  customToken,
  isSubmit
}) {
  const [isOpen, setIsOpen] = useState(false);
  function handleChange(index, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index][key] = value;
    onChange((prev) => newFormVal);
  }
  function handleDeletePOC(index) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal.splice(index, 1);
    onChange((prev) => newFormVal);
  }

  function handleAddUser() {
    setIsOpen(true);
  }

  const renderContactItem = (contactItem, index) => {
    return (
      <div className="block_poc-item">
        <div className="block_poc-item-wrapper">
          <div className="block_poc-item-wrapper-form">
            <Input
              title="First Name*"
              type="text"
              placeholder="First Name*"
              value={contactItem?.firstName}
              onChange={(evt) =>
                handleChange(index, "firstName", evt.target.value)
              }
              error={
                isSubmit &&
                (!contactItem?.firstName || contactItem?.firstName === "")
              }
              errorMsg="First Name is required"
            ></Input>
            <Input
              title="Last Name*"
              type="text"
              placeholder="Last Name*"
              value={contactItem?.lastName}
              onChange={(evt) =>
                handleChange(index, "lastName", evt.target.value)
              }
              error={
                isSubmit &&
                (!contactItem?.lastName || contactItem?.lastName === "")
              }
              errorMsg="Last Name is required"
            ></Input>
            <div>
              <Input
                title="Email*"
                type="text"
                placeholder="Email*"
                value={contactItem?.email}
                // onChange={(evt) => handleChange(index, "email", evt.target.value)}
              ></Input>
            </div>

            <Input
              title="Cell Phone*"
              type="text"
              placeholder="Cell Phone*"
              inputType="TYPE_INPUT_PHONE"
              value={contactItem?.telephone}
              onChange={(evt) =>
                handleChange(index, "telephone", evt.target.value)
              }
            ></Input>
            <Input
              title="Direct Line"
              type="text"
              placeholder="Direct Line"
              inputType="TYPE_INPUT_PHONE"
              value={contactItem?.directLine}
              onChange={(evt) =>
                handleChange(index, "directLine", evt.target.value)
              }
            ></Input>
            <div className="custom-input-container">
              <p className="input-title">Preferred Contact Method</p>
              <Radio.Group
                onChange={(evt) =>
                  handleChange(index, "preferredMethod", evt.target.value)
                }
                value={contactItem?.preferredMethod}
              >
                {PREFERRED_CONTACT_METHOD_LIST.map((item) => (
                  <Radio value={item.value}>{item.label}</Radio>
                ))}
              </Radio.Group>
            </div>
            <div className="custom-input-container">
              <p className="input-title">Role</p>
              <Select
                style={{ width: "120px" }}
                value={contactItem?.role}
                onChange={(val) => handleChange(index, "role", val)}
                options={ROLE_LIST}
              />
            </div>

            <div className="custom-input-container">
              <p className="input-title">Primary</p>
              <Switch
                checked={contactItem?.isPrimary}
                onChange={(checked) =>
                  handleChange(index, "isPrimary", checked)
                }
              />
            </div>
            <div className="custom-input-container">
              <p className="input-title">Active</p>
              <Switch
                checked={contactItem?.isActive}
                onChange={(checked) => handleChange(index, "isActive", checked)}
              />
            </div>
          </div>
        </div>
        <div className="block_poc-item-action">
          {!disabled && (
            <Button
              className="block_poc-item-action-btn"
              onClick={() => handleDeletePOC(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="block_poc">
      {(data || []).map((item, index) => renderContactItem(item, index, true))}
      {!disabled && !disableAdd && (
        <div className="block_poc-footer">
          <Button className="block_poc-footer-link" onClick={handleAddUser}>
            <UserAddOutlined />
            Add User
          </Button>
        </div>
      )}
      {disabled && <ScreenMask />}
      <POCCreate
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setFranchiseeData={setFranchiseeData}
        franchiseeData={franchiseeData}
        customToken={customToken}
      />
    </div>
  );
}
