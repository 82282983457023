import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Checkbox, Button } from "antd";
import "./style.scss";
import { mapFieldAuto } from "../../../../utils/form-recog";
import {
  buildAnyAuto,
  buildAllOwnedAuto,
  buildHiredAuto,
  buildScheduledAuto,
  buildNonOwnedAuto,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueLT,
  buildValueGT
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { formatToMonthDayYear } from "@utils/date-formatter";

const DEFAULT_VALUE = {
  automotive_liability_combined_single_limit: 1000000,
  automotive_liability_bodily_injury_pp: 1000
};


export default function AutoForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.automotive_liability_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        automotive_liability_any_auto: finalData.automotive_liability_any_auto,
        automotive_liability_all_owned_autos:
          finalData.automotive_liability_all_owned_autos,
        automotive_liability_category_hired_autos:
          finalData.automotive_liability_category_hired_autos,
        automotive_liability_category_scheduled_autos:
          finalData.automotive_liability_category_scheduled_autos,
        automotive_liability_category_non_owned_autos:
          finalData.automotive_liability_category_non_owned_autos,
        automotive_policy_form_additional_insurance:
          finalData.automotive_policy_form_additional_insurance,
        automotive_policy_form_subrogratrion_waived:
          finalData.automotive_policy_form_subrogratrion_waived,
        automotive_liability_combined_single_limit:
          finalData.automotive_liability_combined_single_limit,
        automotive_liability_bodily_injury_pp:
          finalData.automotive_liability_bodily_injury_pp,
        automotive_liability_policy_number:
          finalData.automotive_liability_policy_number,
        automotive_liability_effective_date:
          finalData.automotive_liability_effective_date,
        automotive_liability_expire_date:
          finalData.automotive_liability_expire_date
      };
    }
    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldAuto(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value
    });
  }

 const autoSections = [
   {
     title: "Effective Date",
     valueFn: () =>
       buildEffectDate(
         franchiseeData.finalData.automotive_liability_effective_date,
         franchisorData.automotive_liability_required
       ),
     formValue: formatToMonthDayYear(franchiseeData.finalData.automotive_liability_effective_date)
   },
   {
     title: "Expiration Date",
     valueFn: () =>
       buildExpireDate(
         franchiseeData.finalData.automotive_liability_expire_date,
         franchisorData.automotive_liability_required
       ),
     formValue: formatToMonthDayYear(franchiseeData.finalData.automotive_liability_expire_date)
   },
   {
     title: "Combined Single Limit",
     valueFn: () =>
       buildValueGT(
         franchiseeData.finalData.automotive_liability_combined_single_limit,
         franchisorData.automotive_liability_combined_single_limit,
         franchisorData.automotive_liability_required
       ),
     fddRequirement: franchisorData.automotive_liability_combined_single_limit,
     formValue:
       franchiseeData.finalData.automotive_liability_combined_single_limit,
   },
   {
     title: "Maximum Required Deductible",
     valueFn: () =>
       buildValueLT(
         franchiseeData.finalData.automotive_liability_bodily_injury_pp,
         franchisorData.automotive_liability_bodily_injury_pp,
         franchisorData.automotive_liability_required
       ),
     fddRequirement: franchisorData.automotive_liability_bodily_injury_pp,
     formValue: franchiseeData.finalData.automotive_liability_bodily_injury_pp,
   },
   {
     title: "Any Auto",
     valueFn: () =>
       buildAnyAuto(
         franchiseeData.finalData.automotive_liability_any_auto,
         franchisorData.automotive_liability_any_auto,
         franchisorData.automotive_liability_required
       ),
     fddRequirement: franchisorData.automotive_liability_any_auto
       ? "Selected"
       : "Unselected",
     formValue: franchiseeData.finalData.automotive_liability_any_auto,
   },
   {
     title: "All Owned Autos",
     valueFn: () =>
       buildAllOwnedAuto(
         franchiseeData.finalData.automotive_liability_all_owned_autos,
         franchisorData.automotive_liability_all_owned_autos,
         franchiseeData.finalData.automotive_liability_any_auto ||
           (franchiseeData.finalData
             .automotive_liability_category_non_owned_autos &&
             franchiseeData.finalData
               .automotive_liability_category_hired_autos) ||
           (franchiseeData.finalData
             .automotive_liability_category_scheduled_autos &&
             franchiseeData.finalData
               .automotive_liability_category_hired_autos) ||
           (franchiseeData.finalData
             .automotive_liability_category_non_owned_autos &&
             franchiseeData.finalData
               .automotive_liability_category_scheduled_autos),
         franchisorData.automotive_liability_required
       ),
     fddRequirement: franchisorData.automotive_liability_all_owned_autos
       ? "Selected"
       : "Unselected",
     formValue: franchiseeData.finalData.automotive_liability_all_owned_auto,
   },
   {
     title: "Scheduled Autos",
     valueFn: () =>
       buildScheduledAuto(
         franchiseeData.finalData.automotive_liability_category_scheduled_autos,
         franchisorData.automotive_liability_category_scheduled_autos,
         franchiseeData.finalData.automotive_liability_any_auto,
         franchisorData.automotive_liability_required ||
           (franchiseeData.finalData
             .automotive_liability_category_hired_autos &&
             franchiseeData.finalData
               .automotive_liability_category_non_owned_autos)
       ),
     fddRequirement:
       franchisorData.automotive_liability_category_scheduled_autos
         ? "Selected"
         : "Unselected",
     formValue:
       franchiseeData.finalData.automotive_liability_category_scheduled_autos,
   },
   {
     title: "Hired Autos",
     valueFn: () =>
       buildHiredAuto(
         franchiseeData.finalData.automotive_liability_category_hired_autos,
         franchisorData.automotive_liability_category_hired_autos,
         franchiseeData.finalData.automotive_liability_any_auto,
         franchisorData.automotive_liability_required
       ),
     fddRequirement: franchisorData.automotive_liability_category_hired_autos
       ? "Selected"
       : "Unselected",
     formValue:
       franchiseeData.finalData.automotive_liability_category_hired_autos,
   },
   {
     title: "Non-Owned Autos",
     valueFn: () =>
       buildNonOwnedAuto(
         franchiseeData.finalData.automotive_liability_category_non_owned_autos,
         franchisorData.automotive_liability_category_non_owned_autos,
         franchiseeData.finalData.automotive_liability_any_auto,
         franchisorData.automotive_liability_required
       ),
     fddRequirement:
       franchisorData.automotive_liability_category_non_owned_autos
         ? "Selected"
         : "Unselected",
     formValue:
       franchiseeData.finalData.automotive_liability_category_non_owned_autos,
   },
   {
     title: "Additional Insured",
     valueFn: () =>
       buildGeneralLiabilityAdditionalInsurance(
         franchiseeData.finalData.automotive_policy_form_additional_insurance,
         franchisorData.automotive_policy_form_additional_insurance,
         franchisorData.automotive_liability_required
       ),
     fddRequirement: franchisorData.automotive_policy_form_additional_insurance
       ? "Selected"
       : "Unselected",
     formValue:
       franchiseeData.finalData.automotive_policy_form_additional_insurance,
   },
   {
     title: "Waiver of Subrogation",
     valueFn: () =>
       buildGeneralLiabilitySubrogrationWaved(
         franchiseeData.finalData.automotive_policy_form_subrogratrion_waived,
         franchisorData.automotive_policy_form_subrogratrion_waived,
         franchisorData.automotive_liability_required
       ),
     fddRequirement: franchisorData.automotive_policy_form_subrogratrion_waived
       ? "Selected"
       : "Unselected",
   },
 ];

return (
  <div className="liability-table">
    <div className="table-header">
      <span className="table-header-field">Field</span>
      <span className="table-header-fdd">Requirement</span>
      <span className="table-header-coverage">Your Coverage</span>
    </div>
    {autoSections.map(({ title, valueFn, fddRequirement, formValue }) => {
      const value = valueFn()?.[0]?.title;
      const displayValue = formValue || "No Value";
      const isNotMatched = !value && !formValue;

      return (toggleState ? value : formValue || value) ? (
        <div key={title} className="table-row">
          <div className="table-cell field-title">
            <span>{title}</span>
          </div>
          <div className="table-cell fdd-requirement">
            <span>{fddRequirement || ""}</span>
          </div>
          <div className="table-cell">
            {isNotMatched ? (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                {displayValue}
              </span>
            ) : !toggleState ? (
              value ? (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
                </span>
              ) : (
                <span className="value-green">
                  <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                  {typeof displayValue === "boolean"
                    ? displayValue
                      ? "Selected"
                      : null
                    : displayValue}
                </span>
              )
            ) : (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
              </span>
            )}
          </div>
        </div>
      ) : null;
    })}
  </div>
);


}
