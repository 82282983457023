import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_UMBRELLA_LIABILITY,
  FORM_EXCESS_LIABILITY,
  FORM_DEDUCTIBLE,
  FORM_RETENTION,
  FORM_NONE,
} from "../../../../assets/const/form";

import { mapFieldUmbrella } from "../../../../utils/form-recog";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildUmbrellaSubrogrationLiability,
  buildUmbrellaSubrogrationActivity,
  buildUmbrellaSubrogrationStatus,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import {
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import { capitalize } from "lodash";
import { formatToMonthDayYear } from "@utils/date-formatter";

export default function UmbrellaForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.umbrella_subrogration_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        umbrella_subrogration_each_occurance:
          finalData.umbrella_subrogration_each_occurance,
        umbrella_subrogration_aggregate:
          finalData.umbrella_subrogration_aggregate,
        umbrella_subrogration_liability:
          finalData.umbrella_subrogration_liability,
        umbrella_subrogration_activity:
          finalData.umbrella_subrogration_activity,
        umbrella_subrogration_status: finalData.umbrella_subrogration_status,
        umbrella_subrogration_policy_number:
          finalData.umbrella_subrogration_policy_number,
        umbrella_subrogration_effective_date:
          finalData.umbrella_subrogration_effective_date,
        umbrella_subrogration_expire_date:
          finalData.umbrella_subrogration_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldUmbrella(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

const umbrellaSections = [
  {
    title: "Effective Date",
    valueFn: () =>
      buildEffectDate(
        franchiseeData.finalData.umbrella_subrogration_effective_date,
        franchisorData.umbrella_subrogration_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.umbrella_subrogration_effective_date)
  },
  {
    title: "Expiration Date",
    valueFn: () =>
      buildExpireDate(
        franchiseeData.finalData.umbrella_subrogration_expire_date,
        franchisorData.umbrella_subrogration_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.umbrella_subrogration_expire_date)
  },
  {
    title: "Each Occurrence",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.umbrella_subrogration_each_occurance,
        franchisorData.umbrella_subrogration_each_occurance,
        franchisorData.umbrella_subrogration_required
      ),
    fddRequirement: franchisorData.umbrella_subrogration_each_occurance,
    formValue: franchiseeData.finalData.umbrella_subrogration_each_occurance,
  },
  {
    title: "Aggregate",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.umbrella_subrogration_aggregate,
        franchisorData.umbrella_subrogration_aggregate,
        franchisorData.umbrella_subrogration_required
      ),
    fddRequirement: franchisorData.umbrella_subrogration_aggregate,
    formValue: franchiseeData.finalData.umbrella_subrogration_aggregate,
  },
  {
    title: "Subrogation Activity",
    valueFn: () =>
      buildUmbrellaSubrogrationActivity(
        franchiseeData.finalData.umbrella_subrogration_activity,
        franchisorData.umbrella_subrogration_activity,
        franchisorData.umbrella_subrogration_required
      ),
    fddRequirement: capitalize(franchisorData.umbrella_subrogration_activity),
    formValue: capitalize(
      franchiseeData.finalData.umbrella_subrogration_activity
    ),
  },
  {
    title: "Subrogation Liability",
    valueFn: () =>
      buildUmbrellaSubrogrationLiability(
        franchiseeData.finalData.umbrella_subrogration_liability,
        franchisorData.umbrella_subrogration_liability,
        franchisorData.umbrella_subrogration_required
      ),
    fddRequirement: capitalize(franchisorData.umbrella_subrogration_liability),
    formValue: capitalize(
      franchiseeData.finalData.umbrella_subrogration_liability
    ),
  },
  {
    title: "Subrogation Status",
    valueFn: () =>
      buildUmbrellaSubrogrationStatus(
        franchiseeData.finalData.umbrella_subrogration_status,
        franchisorData.umbrella_subrogration_status,
        franchisorData.umbrella_subrogration_required
      ),
    fddRequirement: capitalize(franchisorData.umbrella_subrogration_status),
    formValue: capitalize(
      franchiseeData.finalData.umbrella_subrogration_status
    ),
  },
];


return (
  <div className="liability-table">
    <div className="table-header">
      <span className="table-header-field">Field</span>
      <span className="table-header-fdd">Requirement</span>
      <span className="table-header-coverage">Your Coverage</span>
    </div>
    {umbrellaSections.map(({ title, valueFn, fddRequirement, formValue }) => {
      const value = valueFn()?.[0]?.title;
      const displayValue = formValue || "No Value";
      const isNotMatched = !value && !formValue;

      return (toggleState ? value : formValue || value) ? (
        <div key={title} className="table-row">
          <div className="table-cell field-title">
            <span>{title}</span>
          </div>
          <div className="table-cell fdd-requirement">
            <span>{fddRequirement || ""}</span>
          </div>
          <div className="table-cell">
            {isNotMatched ? (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                {displayValue}
              </span>
            ) : !toggleState ? (
              value ? (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
                </span>
              ) : (
                <span className="value-green">
                  <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                  {typeof displayValue === "boolean"
                    ? displayValue
                      ? "Selected"
                      : null
                    : displayValue}
                </span>
              )
            ) : (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
              </span>
            )}
          </div>
        </div>
      ) : null;
    })}
  </div>
);
}
