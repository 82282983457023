import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Checkbox, Button } from "antd";
import "./style.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { put } from "../../../../utils/axios";
import moment from "moment";

import { mapFieldWC } from "../../../../utils/form-recog";
import {
  buildAnyAuto,
  buildAllOwnedAuto,
  buildHiredAuto,
  buildScheduledAuto,
  buildNonOwnedAuto,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueLT,
  buildValueGT,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { formatToMonthDayYear } from "@utils/date-formatter";

const DEFAULT_VALUE = {
  workers_compensation_el_each_accident: 1000000,
  workers_compensation_other_el_disease_each_employer: 1000000,
  workers_compensation_el_disease_policy_limit: 1000000,
};

export default function WorkersCompForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [formVal, setFormVal] = useState(handleGetFormVal());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.workers_compensation_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        workers_compensation_subrogration_waved:
          finalData.workers_compensation_subrogration_waved,
        workers_compensation_el_each_accident:
          finalData.workers_compensation_el_each_accident,
        workers_compensation_other_el_disease_each_employer:
          finalData.workers_compensation_other_el_disease_each_employer,
        workers_compensation_el_disease_policy_limit:
          finalData.workers_compensation_el_disease_policy_limit,
        workers_compensation_policy_number:
          finalData.workers_compensation_policy_number,
        workers_compensation_effective_date:
          finalData.workers_compensation_effective_date,
        workers_compensation_expire_date:
          finalData.workers_compensation_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldWC(data);
      }
    }

    return {};
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

const workersCompSections = [
  {
    title: "Effective Date",
    valueFn: () =>
      buildEffectDate(
        franchiseeData.finalData.workers_compensation_effective_date,
        franchisorData.workers_compensation_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.workers_compensation_effective_date)
  },
  {
    title: "Expiration Date",
    valueFn: () =>
      buildExpireDate(
        franchiseeData.finalData.workers_compensation_expire_date,
        franchisorData.workers_compensation_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.workers_compensation_expire_date)
  },
  {
    title: "Each Accident",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.workers_compensation_el_each_accident,
        franchisorData.workers_compensation_el_each_accident,
        franchisorData.workers_compensation_required
      ),
    fddRequirement: franchisorData.workers_compensation_el_each_accident,
    formValue: franchiseeData.finalData.workers_compensation_el_each_accident,
  },
  {
    title: "Each Employee (Disease)",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData
          .workers_compensation_other_el_disease_each_employer,
        franchisorData.workers_compensation_other_el_disease_each_employer,
        franchisorData.workers_compensation_required
      ),
    fddRequirement:
      franchisorData.workers_compensation_other_el_disease_each_employer,
    formValue:
      franchiseeData.finalData
        .workers_compensation_other_el_disease_each_employer,
  },
  {
    title: "Policy Limit (Disease)",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.workers_compensation_el_disease_policy_limit,
        franchisorData.workers_compensation_el_disease_policy_limit,
        franchisorData.workers_compensation_required
      ),
    fddRequirement: franchisorData.workers_compensation_el_disease_policy_limit,
    formValue:
      franchiseeData.finalData.workers_compensation_el_disease_policy_limit,
  },
  {
    title: "Waiver of Subrogation",
    valueFn: () =>
      buildGeneralLiabilitySubrogrationWaved(
        franchiseeData.finalData.workers_compensation_subrogration_waved,
        franchisorData.workers_compensation_subrogration_waved,
        franchisorData.workers_compensation_required
      ),
    fddRequirement: franchisorData.workers_compensation_subrogration_waved
      ? "Selected"
      : "Unselected",
    formValue: franchiseeData.finalData.workers_compensation_subrogration_waved,
  },
];

return (
  <div className="liability-table">
    <div className="table-header">
      <span className="table-header-field">Field</span>
      <span className="table-header-fdd">Requirement</span>
      <span className="table-header-coverage">Your Coverage</span>
    </div>
    {workersCompSections.map(({ title, valueFn, fddRequirement, formValue }) => {
      const value = valueFn()?.[0]?.title;
      const displayValue = formValue || "No Value";
      const isNotMatched = !value && !formValue;

      return (toggleState ? value : formValue || value) ? (
        <div key={title} className="table-row">
          <div className="table-cell field-title">
            <span>{title}</span>
          </div>
          <div className="table-cell fdd-requirement">
            <span>{fddRequirement || ""}</span>
          </div>
          <div className="table-cell">
            {isNotMatched ? (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                {displayValue}
              </span>
            ) : !toggleState ? (
              value ? (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
                </span>
              ) : (
                <span className="value-green">
                  <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                  {typeof displayValue === "boolean"
                    ? displayValue
                      ? "Selected"
                      : null
                    : displayValue}
                </span>
              )
            ) : (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
              </span>
            )}
          </div>
        </div>
      ) : null;
    })}
  </div>
);

}
