import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildPollutionType,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { FORM_CLAIMS_MADE, FORM_OCCURRENCE } from "@assets/const/form";
import { mapFieldProLiab } from "@utils/form-recog";
import {
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import { capitalize } from "lodash";
import { formatToMonthDayYear } from "@utils/date-formatter";


export default function ProLiabForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.pro_liab_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        pro_liab_occ: finalData.pro_liab_occ,
        pro_liab_agg: finalData.pro_liab_agg,
        pro_liab_type: finalData.pro_liab_type,
        pro_liab_policy_number: finalData.pro_liab_policy_number,
        pro_liab_effective_date: finalData.pro_liab_effective_date,
        pro_liab_expire_date: finalData.pro_liab_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];

      if (data) {
        return mapFieldProLiab(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

const proLiabSections = [
  {
    title: "Effective Date",
    valueFn: () =>
      buildEffectDate(
        franchiseeData.finalData.pro_liab_effective_date,
        franchisorData?.pro_liab_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.pro_liab_effective_date)
  },
  {
    title: "Expiration Date",
    valueFn: () =>
      buildExpireDate(
        franchiseeData.finalData.pro_liab_expire_date,
        franchisorData.pro_liab_required
      ),
    formValue: formatToMonthDayYear(franchiseeData.finalData.pro_liab_expire_date)
  },
  {
    title: "Each Occurrence",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_liab_occ,
        franchisorData.pro_liab_occ,
        franchisorData?.pro_liab_required
      ),
    fddRequirement: franchisorData.pro_liab_occ,
    formValue: franchiseeData.finalData.pro_liab_occ,
  },
  {
    title: "Aggregate",
    valueFn: () =>
      buildValueGT(
        franchiseeData.finalData.pro_liab_agg,
        franchisorData.pro_liab_agg,
        franchisorData.pro_liab_required
      ),
    fddRequirement: franchisorData.pro_liab_agg,
    formValue: franchiseeData.finalData.pro_liab_agg,
  },
  {
    title: "Professional Liability Type",
    valueFn: () =>
      buildPollutionType(
        franchiseeData.finalData.pro_liab_type,
        franchisorData.pro_liab_type,
        franchisorData.pro_liab_required
      ),
    fddRequirement: capitalize(franchisorData.pro_liab_type),
    formValue: capitalize(franchiseeData.finalData.pro_liab_type),
  },
];


return (
  <div className="liability-table">
    <div className="table-header">
      <span className="table-header-field">Field</span>
      <span className="table-header-fdd">Requirement</span>
      <span className="table-header-coverage">Your Coverage</span>
    </div>
    {proLiabSections.map(({ title, valueFn, fddRequirement, formValue }) => {
      const value = valueFn()?.[0]?.title;
      const displayValue = formValue || "No Value";
      const isNotMatched = !value && !formValue;

      return (toggleState ? value : formValue || value) ? (
        <div key={title} className="table-row">
          <div className="table-cell field-title">
            <span>{title}</span>
          </div>
          <div className="table-cell fdd-requirement">
            <span>{fddRequirement || ""}</span>
          </div>
          <div className="table-cell">
            {isNotMatched ? (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                {displayValue}
              </span>
            ) : !toggleState ? (
              value ? (
                <span className="value-red">
                  <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
                </span>
              ) : (
                <span className="value-green">
                  <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                  {typeof displayValue === "boolean"
                    ? displayValue
                      ? "Selected"
                      : null
                    : displayValue}
                </span>
              )
            ) : (
              <span className="value-red">
                <WarningTwoTone twoToneColor={"#ff0000"} /> {value}
              </span>
            )}
          </div>
        </div>
      ) : null;
    })}
  </div>
);
}
